import "../App.css";
import io from "socket.io-client";
import uk from "../assets/uk.png";
import uae from "../assets/uae.png";
import moment from "moment-timezone";
import logo from "../assets/logo.png";
import america from "../assets/america.png";
import Blocked from "../components/Blocked";
import bullionStats from "../assets/bullioStats.png";
import MarketClose from "../components/MarketClose";
import React, { useEffect, useMemo, useState } from "react";
import { commodityCalculation } from "../components/constants";
import EconomicNewsModal from "../components/EconomicNewsModal";
import SubscriptionExpired from "../components/SubscriptionExpired";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { Box, LinearProgress, Modal, Typography } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import SubscriptionExpiringSoon from "../components/SubscriptionExpiringSoon";
import {
  allCommodities,
  allNewses,
  getAdminProfile,
  getEconomicNews,
  getGoldPriceNews,
  getLiveValueTypeForDisplay,
  getRatio,
  getSocketUrl,
  getSpread,
} from "../sevice/home";

const { makeStyles } = require("@mui/styles");

let socket = localStorage.getItem("socketUrl")
  ? io(JSON.parse(localStorage.getItem("socketUrl")))
  : null;

const socket2 = io(process.env.REACT_APP_BACKEND_URL);
const adminId = process.env.REACT_APP_ADMIN_ID;

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    backgroundColor: "#330411",
    width: "100%",
    height: "100vh",
    margin: "auto",
    display: "grid",
    gridTemplateColumns: "1.1fr 2fr",
    gridTemplateRows: "1fr",
    gridTemplateAreas: `
        'leftPart rightPart'
      `,
    padding: "3rem 3rem 2rem 3rem",
    boxSizing: "border-box",
    columnGap: "2rem",
  },
  leftPart: {
    boxSizing: "border-box",
    gridArea: "leftPart",
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },

  logo: {
    height: "27%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  logoImg: {
    width: "35%",
    objectFit: "contain",
  },
  spotRate: {
    height: "52%",
    boxSizing: "border-box",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-evenly",
    gap: ".6rem",
    borderRadius: "10px",
    color: "#EDCA9F",
  },
  spotRateBoxGoldRowCol: {
    display: "flex",
    flexDirection: "column",
    gap: "0.3rem",
    alignItems: "center",
    flexBasis: 0,
    flexGrow: 1,
    flexShrink: 1,
  },
  spotRateValueGold: {
    border: "1px solid #EDCA9F",
    color: "#FFFFFF",
    borderRadius: "5px",
    width: "7.3vw",
    height: "7vh",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    transition: "background-color .5s ease",
  },
  spotRateValueSilver: {
    border: "1px solid #EDCA9F",
    color: "#FFFFFF",
    borderRadius: "5px",
    width: "7.3vw",
    height: "6vh",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    transition: "background-color .5s ease",
  },
  heighAndLow: {
    display: "flex",
    gap: "5px",
    alignItems: "center",
    width: "8.8vw",
    justifyContent: "start",
    color: "#FFFFFF",
  },
  stat: {
    height: "13%",
    backgroundColor: "#481120",
    borderRadius: "5px",
    color: "#fff",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  bullionStatsImg: {
    width: "10.5vw",
    height: "auto",
  },
  rightPart: {
    gridArea: "rightPart",
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "space-between",
    boxSizing: "border-box",
    flexDirection: "column",
  },
  header: {
    height: "28%",
  },
  currency: {
    display: "grid",
    gap: "1em",
    gridTemplateColumns: "38% 31% 31%",
    color: "#EDCA9F",
    alignItems: "start",
  },
  leenLogoImg: {
    width: "100%",
    alignItems: "center",
    height: "100%",
    objectFit: "contain",
  },

  commoditieTable: {
    height: "57.5%",
    display: "flex",
    boxSizing: "border-box",
    flexDirection: "column",
    color: "#000000",
  },
  table: {
    gridArea: "table",
    gap: "15px",
    boxSizing: "border-box",
    display: "flex",
    flexDirection: "column",
    height: "100%",
  },
  tabeHeader: {
    backgroundColor: "#EDCA9F",
    flex: "1",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "15%",
    color: "#330411",
  },
  tableContent: {
    gridArea: "tableContent",
    display: "flex",
    height: "83%",
    flexDirection: "column",
    color: "#FFFFFF",
    overflowX: "hidden",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
  tableRowColumn: {
    flex: "1",
    display: "flex",
    alignItems: "center",
    fontSize: "2vw",
  },
  tableRow1: {
    flexBasis: 0,
    minHeight: "15%",
    maxHeight: "15%",
    flexGrow: 1,
    gridArea: "tableRow1",
    backgroundColor: "#481120",
    display: "flex",
    borderBottom: "6px solid #806E59",
  },

  updates: {
    display: "flex",
    height: "8%",
    color: "#FFFFFF",
  },
  updatesContent: {
    display: "flex",
    alignItems: "center",
    alignSelf: "center",
    backgroundColor: "#481120",
    height: "80%",
    width: "100%",
  },

  blur: {
    filter: "blur(8px)",
  },
}));

const Home = () => {
  const classes = useStyles();
  const [adminData, setAdminData] = useState();
  const [openSubscriptionExpireSoon, setOpenSubscriptionExpireSoon] =
    useState(false);
  const [openSubscriptionExpierd, setOpenSubscriptionExpierd] = useState(false);
  const [openBlocked, setOpenBlocked] = useState(false);
  const [isMarketOpen, setIsMarketOpen] = useState(true);
  const [render, setRender] = useState(true);

  const [gold, setGold] = useState({
    cur: { ask: 0, bid: 0, highPrice: 0, lowPrice: 0 },
    pre: { ask: 0, bid: 0, highPrice: 0, lowPrice: 0 },
  });
  const [silver, setSilver] = useState({
    cur: { ask: 0, bid: 0, highPrice: 0, lowPrice: 0 },
    pre: { ask: 0, bid: 0, highPrice: 0, lowPrice: 0 },
  });
  const [ratio, setRatio] = useState({
    Buyers: "0%",
    chgValue: "+0%",
    commodity: "Gold",
  });
  const [displayBidOrBuy, setDisplayBidOrBuy] = useState({
    bidOrBuy: "Bid",
    askOrSell: "Ask",
  });
  const [spread, setSpread] = useState({
    goldAskSpread: 0,
    goldBidSpread: 0,
    silverAskSpread: 0,
    silverBidSpread: 0,
    goldLowSpread: 0,
    goldHighSpread: 0,
    silverLowSpread: 0,
    silverHighSpread: 0,
  });
  const [news, setNews] = useState([]);
  const [goldNews, setGoldNews] = useState({});
  const [commodities, setCommodities] = useState([]);

  ////////////////////// Block //////////////////////////
  const handleOpenBlocked = () => setOpenBlocked(true);
  const handleCloseBlocked = () => {
    window.location.reload();
  };

  ///////////////////// Subscription Expierd /////////////////
  const handleOpenSubscriptionExpierd = () => setOpenSubscriptionExpierd(true);
  const handleCloseSubscriptionExpierd = () => window.location.reload();

  ///////////////////// Subscription ExpierSoon /////////////////////
  const handleExpireSoonOpen = () => setOpenSubscriptionExpireSoon(true);
  const handleOpenExpireSoonClose = () => setOpenSubscriptionExpireSoon(false);

  ///////////////////// Check is Market Closed  /////////////////////
  useEffect(() => {
    const interval = setInterval(() => {
      const now = moment().tz(adminData?.time_zone);
      const dayOfWeek = now.day();
      const hour = now.hour();
      if (
        (dayOfWeek >= 1 && dayOfWeek <= 5 && hour >= 1 && hour < 2) || // Monday to Friday, 1AM to 2AM
        (dayOfWeek === 6 && hour >= 1) || // Saturday, 1AM onwards
        dayOfWeek === 0 || // Sunday
        (dayOfWeek === 1 && hour < 2) // Monday, before 2AM
      ) {
        setIsMarketOpen(false);
      } else {
        setIsMarketOpen(true);
      }
    }, 1000); // Check every minute

    return () => clearInterval(interval);
  }, [adminData?.time_zone]);

  useMemo(() => {
    if (socket && render) {
      setRender(false);
      socket.on("connect", () => {});
      socket.on("disconnect", () => {});
      socket.on("connect_error", (err) => {
        console.log(`connect_error due to ${err.message}`);
      });
      socket.on("gold-rate-change", (data) => {
        // setIsMarketOpen(data.data.isMarketOpen);
        setGold((e) => {
          return { cur: data.data, pre: e.cur };
        });
      });
      socket.on("silver-rate-change", (data) => {
        setSilver((e) => {
          return { cur: data.data, pre: e.cur };
        });
      });
    }
  }, [socket]);

  useEffect(() => {
    socket2.on("connect", () => {});
    socket2.on("disconnect", () => {});
    socket2.emit("join_room", { roomId: adminId });
    socket2.on("commodityRerender", (data) => {
      handleFindCommodities();
    });
    socket2.on("newsRerender", (data) => {
      hadlefetchNews();
    });
    socket2.on("spreadRerender", (data) => {
      handleFindSpread();
    });
    socket2.on("loginChecking", (data) => {
      // handleloginChecking();
    });
    socket2.on("adminBlockRerender", (data) => {
      handleFindAdminProfile();
    });
    socket2.on("socketRerender", (data) => {
      localStorage.removeItem("socketUrl");
      window.location.reload();
    });
    socket2.on("reloadRender", (data) => {
      window.location.reload();
    });
    socket2.on("upgradeRerender", (data) => {
      handleFindAdminProfile();
    });
    socket2.on("bidAndAskTitleRerender", (data) => {
      handleFindLiveValueTypeForDisplay();
    });
    socket2.on("economicNewsRender", (data) => {
      handleFindEconomicNews();
    });
  }, []);

  const handleFindCommodities = async () => {
    const res = await allCommodities();
    if (Array.isArray(res.commodities)) {
      setCommodities(res.commodities);
    }
  };
  const handleFindSpread = async () => {
    const res = await getSpread();
    if (res) {
      setSpread(res);
    }
  };
  const hadlefetchNews = async () => {
    const res = await allNewses();
    if (Array.isArray(res)) {
      setNews(res);
    }
  };
  const handleFindGoldPriceNews = async () => {
    const res = await getGoldPriceNews();
    if (res.length > 0) setGoldNews(res[0]);
  };

  const handleFindLiveValueTypeForDisplay = async () => {
    const res = await getLiveValueTypeForDisplay();
    if (res) {
      setDisplayBidOrBuy(res);
    }
  };
  const handleFindRatio = async () => {
    const res = await getRatio();
    if (res?.data) {
      setRatio(res.data);
    }
  };
  const handleFindSocketUrl = async () => {
    const res = await getSocketUrl();
    if (res) {
      socket = io(res?.socketURL);
      localStorage.setItem("socketUrl", JSON.stringify(res?.socketURL));
    }
  };

  const handleFindAdminProfile = async () => {
    const res = await getAdminProfile();
    setAdminData(res);
    if (!res.status || res.isBlocked) {
      handleOpenBlocked();
    } else if (!res.isBlocked && openBlocked) {
    } else {
      handlecheckSubscriptionExpierd(res);
    }
  };

  const handlecheckSubscriptionExpierd = (data) => {
    const givenDate = new Date(data?.package.expire_date);
    let a = givenDate.toISOString();
    let b = new Date().toISOString();
    if (a < b) {
      handleOpenSubscriptionExpierd();
    }
  };

  useEffect(() => {
    if (adminData) {
      const interval = setInterval(() => {
        checkSubscriptionExpireSoon(adminData);
      }, 60 * 60 * 1000);
      return () => clearInterval(interval);
    }
  }, [adminData]);

  const checkSubscriptionExpireSoon = (data) => {
    const givenDate = new Date(data?.package.expire_date);
    const oneMonthAgo = new Date(givenDate);
    oneMonthAgo.setDate(givenDate.getDate() - 30);
    let a = oneMonthAgo.toISOString();
    let b = new Date().toISOString();
    if (b >= a) {
      const currentDate = new Date();
      const currentHour = currentDate.getHours();
      if (currentHour === 10) {
        handleExpireSoonOpen();
      }
    }
  };

  useEffect(() => {
    handleFindCommodities();
    handleFindSpread();
    hadlefetchNews();
    handleFindLiveValueTypeForDisplay();
    handleFindRatio();
    handleFindSocketUrl();
    handleFindAdminProfile();
    handleFindEconomicNews();
    handleFindGoldPriceNews();
  }, []);

  const [economicNews, setEconomicNews] = useState([]);
  const [openNews, setOpenNews] = useState(false);
  const handleOpenopenNews = () => setOpenNews(true);
  const handleCloseopenNews = () => setOpenNews(false);

  const [curEconomicNews, setCurEconomicNews] = useState([]);
  const handleFindEconomicNews = async () => {
    const res = await getEconomicNews();
    setEconomicNews(res);
  };

  useEffect(() => {
    const timeInterwell = setInterval(() => {
      economicNewsFinding();
    }, 1000 * 60 * 1);
    economicNewsFinding();
    return () => clearInterval(timeInterwell);
  }, [economicNews]);

  const economicNewsFinding = () => {
    if (economicNews.length) {
      const currentTime = new Date(new Date().toUTCString());
      const oneHourAgo = new Date(currentTime);
      oneHourAgo.setHours(currentTime.getHours() + 1);
      oneHourAgo.setSeconds(0);
      oneHourAgo.setMilliseconds(0);

      const currentTimeMin = new Date(new Date().toUTCString());
      const fiveMinAgo = new Date(currentTimeMin);
      fiveMinAgo.setMinutes(currentTimeMin.getMinutes());
      fiveMinAgo.setSeconds(0);
      fiveMinAgo.setMilliseconds(0);

      let objectBeforeFiveMin = economicNews.filter((obj) => {
        let utcDate = new Date(obj.date);
        let timeDiff =
          new Date(
            utcDate?.toLocaleString("en-US", { timeZone: "UTC" })
          ).getTime() - fiveMinAgo.getTime();

        let minutesDiff = Math.floor(timeDiff / (1000 * 60));
        return minutesDiff <= 5 && minutesDiff >= 0;
      });
      let objectBeforeOneHour = economicNews.filter((obj) => {
        let utcDate = new Date(obj.date);
        let timeDiff =
          new Date(
            utcDate?.toLocaleString("en-US", { timeZone: "UTC" })
          ).getTime() - oneHourAgo.getTime();

        let minutesDiff = Math.floor(timeDiff / (1000 * 60));
        return minutesDiff <= 5 && minutesDiff >= -5;
      });
      if (objectBeforeFiveMin.length) {
        objectBeforeFiveMin = objectBeforeFiveMin?.sort(
          (a, b) => b.priority - a.priority
        );
        setCurEconomicNews(objectBeforeFiveMin);
        handleOpenopenNews();
      } else if (objectBeforeOneHour.length) {
        objectBeforeOneHour = objectBeforeOneHour?.sort(
          (a, b) => b.priority - a.priority
        );
        setCurEconomicNews(objectBeforeOneHour);
        handleOpenopenNews();
      } else {
        setCurEconomicNews([]);
        handleCloseopenNews();
      }
    } else {
      setCurEconomicNews([]);
    }
  };
  const progress = Number(ratio?.Buyers?.match(/\d+/)[0]);
  const buyersColor = "#06990C";
  const sellersColor = "#C20F0F";
  const progressStyle = { backgroundColor: sellersColor };
  const barStyle = { backgroundColor: buyersColor };

  return (
    <div className={(openBlocked || openSubscriptionExpierd) && classes.blur}>
      <Box className={classes.mainContainer}>
        {!isMarketOpen && <MarketClose timeZone={adminData?.time_zone} />}
        <Box className={classes.leftPart}>
          <Box className={classes.logo}>
            <img
              className={classes.logoImg}
              src={logo}
              style={{
                alignItems: "center",
                objectFit: "contain",
              }}
              alt="logo"
            />
          </Box>
          <Box className={classes.spotRate}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                height: "15%",
                justifyContent: "center",
                borderBottom: "1px solid #747781",
              }}
            >
              <Typography
                sx={{
                  fontSize: "1.5VW",
                  fontWeight: "bold",
                }}
              >
                SPOT RATE
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                height: "15%",
                justifyContent: "space-around",
              }}
            >
              <Box className={classes.heighAndLow}>
                <Typography
                  sx={{
                    fontSize: "1vw",
                    backgroundColor: "#C20F0F",
                    borderRadius: "3px",
                    px: 1,
                  }}
                >
                  Low
                </Typography>
                <Box
                  sx={{
                    borderRadius: "50%",
                    border: "1px solid #C20F0F",
                    height: "18px",
                    width: "18px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    color: "#C20F0F",
                  }}
                >
                  <KeyboardArrowDownIcon sx={{ fontWeight: "18px" }} />
                </Box>
                <Typography style={{ fontSize: "1.2vw" }}>
                  {(!gold.cur.bid
                    ? 0
                    : Number(spread.goldLowSpread) + Number(gold?.cur?.lowPrice)
                  ).toFixed(2)}
                </Typography>
              </Box>
              <Box className={classes.heighAndLow}>
                <Typography
                  sx={{
                    fontSize: "1vw",
                    backgroundColor: "#06990C",
                    borderRadius: "3px",
                    px: 1,
                  }}
                >
                  High
                </Typography>
                <Box
                  sx={{
                    borderRadius: "50%",
                    border: "1px solid #06990C",
                    height: "18px",
                    width: "18px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    color: "#06990C",
                  }}
                >
                  <KeyboardArrowUpIcon sx={{ fontSize: "18px" }} />
                </Box>
                <Typography style={{ fontSize: "1.2vw" }}>
                  {(!gold.cur.bid
                    ? 0
                    : Number(spread?.goldHighSpread) +
                      Number(gold?.cur?.highPrice)
                  ).toFixed(2)}
                </Typography>
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                height: "15%",
              }}
            >
              <Typography
                sx={{
                  fontSize: "1.3vw",
                  fontWeight: "bold",
                  flexBasis: 0,
                  flexGrow: 0.8,
                  flexShrink: 1,
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                &nbsp;
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: "5px",
                  flexBasis: 0,
                  flexGrow: 1,
                  flexShrink: 1,
                }}
              >
                <Typography
                  sx={{
                    backgroundColor: "#EDCA9F",
                    borderRadius: "3px",
                    px: "6px",
                    color: "#330411",
                    fontWeight: "bold",
                  }}
                >
                  $
                </Typography>
                <Typography sx={{ fontSize: "1.4vw", fontWeight: "bold" }}>
                  {displayBidOrBuy?.bidOrBuy?.toUpperCase()}
                </Typography>
                <Typography style={{ fontSize: "1.2vw" }}>oz</Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: "5px",
                  flexBasis: 0,
                  flexGrow: 1,
                  flexShrink: 1,
                }}
              >
                <Typography
                  sx={{
                    backgroundColor: "#EDCA9F",
                    borderRadius: "3px",
                    px: "6px",
                    color: "#330411",
                    fontWeight: "bold",
                  }}
                >
                  $
                </Typography>
                <Typography sx={{ fontSize: "1.4vw", fontWeight: "bold" }}>
                  &nbsp;
                  {displayBidOrBuy?.askOrSell?.toUpperCase()}
                </Typography>
                <Typography style={{ fontSize: "1.2vw" }}>oz</Typography>
              </Box>
            </Box>
            <Box
              sx={{
                height: "40%",
                display: "flex",
                alignItems: "center",
                border: "1px solid #EDCA9F",
                borderRadius: "3px",
                color: "#FFFFFF",
              }}
            >
              <Typography
                sx={{
                  fontSize: "1.8vw",
                  fontWeight: "bold",
                  p: 0,
                  flexBasis: 0,
                  flexGrow: 0.8,
                  flexShrink: 1,
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                GOLD
              </Typography>

              <Box className={classes.spotRateBoxGoldRowCol}>
                <Box
                  className={classes.spotRateValueGold}
                  sx={{
                    backgroundColor:
                      Number(gold?.cur?.bid) < Number(gold?.pre?.bid)
                        ? "#C20F0F"
                        : Number(gold?.cur?.bid) > Number(gold?.pre?.bid)
                        ? "#06990C"
                        : "",
                    px: 1.5,
                  }}
                >
                  <Typography sx={{ fontSize: "2vw", fontWeight: "bold" }}>
                    {(!gold.cur.bid
                      ? 0
                      : Number(spread?.goldBidSpread) + Number(gold?.cur?.bid)
                    ).toFixed(2)}
                  </Typography>
                </Box>
              </Box>

              <Box className={classes.spotRateBoxGoldRowCol}>
                <Box
                  className={classes.spotRateValueGold}
                  sx={{
                    backgroundColor:
                      Number(gold?.cur?.ask) < Number(gold?.pre?.ask)
                        ? "#C20F0F"
                        : Number(gold?.cur?.ask) > Number(gold?.pre?.ask)
                        ? "#06990C"
                        : "",
                    px: 1.5,
                  }}
                >
                  <Typography sx={{ fontSize: "2vw", fontWeight: "bold" }}>
                    {(!gold.cur.bid
                      ? 0
                      : Number(spread?.goldAskSpread) + Number(gold?.cur?.ask)
                    ).toFixed(2)}
                  </Typography>
                </Box>
              </Box>
            </Box>
            <Box
              sx={{
                height: "33%",
                display: "flex",
                borderBottom: "1px solid #EDCA9F",
                borderRight: "1px solid #EDCA9F",
                borderLeft: "1px solid #EDCA9F",
                borderRadius: "3px",
                alignItems: "center",
                marginTop: "-0.6rem",
                color: "#FFFFFF",
              }}
            >
              <Typography
                sx={{
                  fontSize: "1.4vw",
                  flexBasis: 0,
                  flexGrow: 0.8,
                  flexShrink: 1,
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                SILVER
              </Typography>

              <Box className={classes.spotRateBoxGoldRowCol}>
                <Box
                  className={classes.spotRateValueSilver}
                  sx={{
                    backgroundColor:
                      Number(silver?.cur?.bid) < Number(silver?.pre?.bid)
                        ? "#C20F0F"
                        : Number(silver?.cur?.bid) > Number(silver?.pre?.bid)
                        ? "#06990C"
                        : "",
                    px: 1.5,
                  }}
                >
                  <Typography sx={{ fontSize: "1.8vw" }}>
                    &nbsp;&nbsp;
                    {(!silver.cur.bid
                      ? 0
                      : Number(spread?.silverBidSpread) +
                        Number(silver?.cur?.bid)
                    ).toFixed(3)}
                    &nbsp;&nbsp;
                  </Typography>
                </Box>
              </Box>

              <Box className={classes.spotRateBoxGoldRowCol}>
                <Box
                  className={classes.spotRateValueSilver}
                  sx={{
                    backgroundColor:
                      Number(silver?.cur?.ask) < Number(silver?.pre?.ask)
                        ? "#C20F0F"
                        : Number(silver?.cur?.ask) > Number(silver?.pre?.ask)
                        ? "#06990C"
                        : "",
                    px: 1.5,
                  }}
                >
                  <Typography sx={{ fontSize: "1.8vw" }}>
                    &nbsp;&nbsp;
                    {(!silver.cur.bid
                      ? 0
                      : Number(spread?.silverAskSpread) +
                        Number(silver?.cur?.ask)
                    ).toFixed(3)}
                    &nbsp;&nbsp;
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>

          <Box className={classes.stat}>
            <>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                  px: 5,
                  py: 1,
                  boxSizing: "border-box",
                }}
              >
                <Typography sx={{ fontSize: "1.1vw" }}>BUYERS</Typography>
                <Typography
                  sx={{
                    fontSize: "1.1vw",
                    color:
                      Number(ratio?.chgValue.replace("%", "")) >= 0
                        ? "#06990C"
                        : "#C20F0F",
                  }}
                >
                  {ratio?.chgValue}
                </Typography>
                <Typography sx={{ fontSize: "1.1vw" }}>SELLERS</Typography>
              </Box>
              <Box sx={{ width: "90%", px: 4 }}>
                <LinearProgress
                  variant="determinate"
                  value={progress}
                  style={progressStyle}
                  sx={{
                    "& .MuiLinearProgress-bar": barStyle,
                  }}
                />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                  px: 7,
                  py: 1,
                  boxSizing: "border-box",
                }}
              >
                <Typography sx={{ fontSize: "1.1vw", color: "#06990C" }}>
                  {ratio?.Buyers}
                </Typography>
                <Box sx={{ mt: 1 }}>
                  <a href="https://www.bullionstats.com/">
                    <img
                      className={classes.bullionStatsImg}
                      src={bullionStats}
                      alt="bullionStats"
                    />
                  </a>
                </Box>{" "}
                <Typography sx={{ fontSize: "1.1vw", color: "#C20F0F" }}>
                  {Number(ratio?.Buyers?.match(/\d+/)[0])
                    ? 100 - Number(ratio?.Buyers?.match(/\d+/)[0])
                    : 0}
                  %
                </Typography>
              </Box>
            </>
          </Box>
        </Box>
        <Box className={classes.rightPart}>
          <Box className={classes.header}>
            <Box className={classes.currency}>
              <Box
                sx={{
                  flex: 1,
                  backgroundColor: "#481120",
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
              >
                <Typography
                  sx={{
                    backgroundColor: "#EDCA9F",
                    textAlign: "center",
                    py: 0.5,
                    borderRadius: "10px 10px  0px  0px",
                    color: "#481120",
                    letterSpacing: "3px",
                    fontWeight: "bold",
                    fontSize: "1.3vw",
                  }}
                >
                  United Arab Emirates
                </Typography>
                <Box
                  sx={{
                    flex: 1,

                    borderRadius: " 0px  0px  10px  10px",
                    display: "flex",
                    gap: "10px",
                    alignItems: "center",
                    p: "20px",
                  }}
                >
                  <img
                    src={uae}
                    style={{ height: "10vh", width: "7.5vw" }}
                    alt="logo"
                  />
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      flexDirection: "column",
                      width: "100%",
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: { lg: "1.75vw", md: "1.4vw" },
                        fontWeight: "bold",
                        display: "flex",
                        width: "80%",
                        justifyContent: "space-between",
                      }}
                    >
                      {moment(Date.now())
                        .tz("Asia/Dubai")
                        .format("hh:mm:ss A")
                        .toUpperCase()
                        .split("")
                        .map((item, index) => (
                          <span key={index}>{item.toUpperCase()}</span>
                        ))}
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: { lg: "1.6vw", md: "1.2vw" },
                        fontWeight: "bold",
                        mt: { lg: -1.5 },
                        color: "#EDCA9F85",
                        display: "flex",
                        width: "80%",
                        justifyContent: "space-between",
                      }}
                    >
                      {moment(Date.now())
                        .tz("Asia/Dubai")
                        .format("DD MMM YYYY")
                        .toUpperCase()
                        .split("")
                        .map((item, index) => (
                          <span key={index}>{item.toUpperCase()}</span>
                        ))}
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: { lg: "1.2vw", md: "1.3vw" },
                        fontWeight: "bold",
                        letterSpacing: "3px",
                        mt: { lg: -1.5 },
                        color: "#EDCA9F85",
                        display: "flex",
                        width: "80%",
                        justifyContent: "space-between",
                      }}
                    >
                      {moment(Date.now())
                        .tz("Asia/Dubai")
                        .format("dddd")
                        .toUpperCase()
                        .split("")
                        .map((item, index) => (
                          <span key={index}>{item.toUpperCase()}</span>
                        ))}
                    </Typography>
                  </Box>
                </Box>
              </Box>
              <Box
                sx={{
                  flex: 1,
                  backgroundColor: "#481120",
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
              >
                <Typography
                  sx={{
                    backgroundColor: "#EDCA9F",
                    textAlign: "center",
                    py: 0.5,
                    borderRadius: "10px 10px  0px  0px",
                    color: "#481120",
                    letterSpacing: "3px",
                    fontWeight: "bold",
                    fontSize: "1.3vw",
                  }}
                >
                  LONDON
                </Typography>
                <Box
                  sx={{
                    flex: 1,
                    backgroundColor: "#481120",
                    borderRadius: " 0px  0px  10px  10px",
                    display: "flex",
                    gap: "10px",
                    alignItems: "center",
                    p: "20px",
                  }}
                >
                  <img
                    src={uk}
                    style={{ height: "10vh", width: "7.5vw" }}
                    alt="logo"
                    srcset=""
                  />
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      flexDirection: "column",
                      width: "100%",
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: { lg: "1.9vw", md: "1.4vw" },
                        fontWeight: "bold",
                      }}
                    >
                      {moment(Date.now())
                        .tz("Europe/London")
                        .format("hh:mm:ss")}
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: { lg: "1.4vw", md: "1.2vw" },
                        fontWeight: "bold",
                        mt: { lg: -1 },
                      }}
                    >
                      {moment(Date.now()).tz("Europe/London").format("A")}
                    </Typography>
                  </Box>
                </Box>
              </Box>
              <Box
                sx={{
                  flex: 1,
                  backgroundColor: "#481120",
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
              >
                <Typography
                  sx={{
                    backgroundColor: "#EDCA9F",
                    textAlign: "center",
                    py: 0.5,
                    borderRadius: "10px 10px  0px  0px",
                    color: "#481120",
                    letterSpacing: "3px",
                    fontWeight: "bold",
                    fontSize: "1.3vw",
                  }}
                >
                  NEW YORK
                </Typography>
                <Box
                  sx={{
                    flex: 1,
                    backgroundColor: "#481120",
                    borderRadius: " 0px  0px  10px  10px",
                    display: "flex",
                    gap: "10px",
                    alignItems: "center",
                    p: "20px",
                  }}
                >
                  <img
                    src={america}
                    style={{ height: "10vh", width: "7.5vw" }}
                    alt="logo"
                    srcset=""
                  />
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      flexDirection: "column",
                      width: "100%",
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: { lg: "1.9vw", md: "1.4vw" },
                        fontWeight: "bold",
                      }}
                    >
                      {moment(Date.now())
                        .tz("America/New_York")
                        .format("hh:mm:ss")}
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: { lg: "1.4vw", md: "1.2vw" },
                        fontWeight: "bold",
                        mt: { lg: -1 },
                      }}
                    >
                      {moment(Date.now()).tz("America/New_York").format("A")}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>

          <Box className={classes.commoditieTable}>
            <Box className={classes.table}>
              <Box className={classes.tabeHeader}>
                <Typography
                  sx={{
                    flex: 1,
                    display: "flex",
                    justifyContent: "center",
                    fontSize: "2vw",
                    fontWeight: "bold",
                  }}
                >
                  COMMODITY
                </Typography>
                <Typography
                  sx={{
                    flex: 1,
                    display: "flex",
                    justifyContent: "center",
                    fontSize: "2vw",
                    fontWeight: "bold",
                  }}
                >
                  WEIGHT
                </Typography>
                <Typography
                  sx={{
                    flex: 1,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    fontSize: "2vw",
                    fontWeight: "bold",
                  }}
                >
                  BUY
                  <Typography sx={{ fontSize: "0.9vw", mt: 2.2 }}>
                    &nbsp;AED
                  </Typography>
                </Typography>
                <Typography
                  sx={{
                    flex: 1,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    fontSize: "2vw",
                    fontWeight: "bold",
                  }}
                >
                  SELL
                  <Typography sx={{ fontSize: "0.9vw", mt: 2.2 }}>
                    &nbsp;AED
                  </Typography>
                </Typography>
              </Box>

              <Box className={classes.tableContent}>
                {commodities?.map((commodity, idx) => {
                  const words = commodity.commodity_title.split(" ");
                  return (
                    <Box
                      key={idx}
                      className={classes.tableRow1}
                      sx={{
                        maxHeight: commodities.length > 6 ? "17%" : "",
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: "1.3vw",
                          fontWeight: "bold",
                          justifyContent: "left",
                        }}
                        className={classes.tableRowColumn}
                      >
                        {words?.map((word, index) => {
                          if (index === 0) {
                            return (
                              <span
                                key={index}
                                style={{
                                  fontSize: "2vw",
                                  fontWeight: "bold",
                                  paddingLeft: "30px",
                                }}
                              >
                                {word.toUpperCase()}
                              </span>
                            );
                          } else {
                            return (
                              <span
                                key={index}
                                style={{
                                  fontSize: "1vw",
                                  marginTop: "0.2rem",
                                  fontWeight: "bold",
                                }}
                              >
                                &nbsp;{word.toUpperCase()}
                              </span>
                            );
                          }
                        })}
                        &nbsp;
                        {commodity.unitLabel === "TTB"
                          ? ""
                          : commodity.displayPurity}
                      </Typography>
                      <Box
                        className={classes.tableRowColumn}
                        sx={{
                          justifyContent: "left",
                        }}
                      >
                        <Typography
                          style={{ fontSize: "2vw", fontWeight: "bold" }}
                          sx={{
                            paddingLeft: { lg: "4.5vw", md: "3.5vw" },
                          }}
                        >
                          {commodity.unit} {commodity.unitLabel}
                        </Typography>
                      </Box>
                      <Box
                        className={classes.tableRowColumn}
                        sx={{
                          justifyContent: "left",
                        }}
                      >
                        <Typography
                          style={{
                            fontSize: "2vw",
                            fontWeight: "bold",
                          }}
                          sx={{
                            paddingLeft: { lg: "3.8vw", md: "3vw" },
                          }}
                        >
                          {!gold?.cur?.bid
                            ? "0.00"
                            : commodityCalculation(
                                commodity.commodity_title === "Silver"
                                  ? silver?.cur?.bid
                                  : gold?.cur?.bid,
                                commodity.commodity_title === "Silver"
                                  ? spread?.silverBidSpread
                                  : spread?.goldBidSpread,
                                commodity?.buy_premium,
                                3.674,
                                commodity.purity,
                                commodity.unit,
                                commodity.unitLabel === "TTB"
                                  ? 116.64
                                  : commodity.unitLabel === "KG"
                                  ? 1000
                                  : commodity.unitLabel === "OZ"
                                  ? 31.1
                                  : commodity.unitLabel === "TOLA"
                                  ? 11.7
                                  : 1,
                                commodity.buy_charge
                              )}
                        </Typography>
                      </Box>
                      <Box
                        className={classes.tableRowColumn}
                        sx={{
                          justifyContent: "left",
                        }}
                      >
                        <Typography
                          style={{ fontSize: "2vw", fontWeight: "bold" }}
                          sx={{
                            paddingLeft: { lg: "3.8vw", md: "3vw" },
                          }}
                        >
                          {!gold?.cur?.bid
                            ? "0.00"
                            : commodityCalculation(
                                commodity.commodity_title === "Silver"
                                  ? silver?.cur?.ask
                                  : gold?.cur?.ask,
                                commodity.commodity_title === "Silver"
                                  ? spread.silverAskSpread
                                  : spread.goldAskSpread,
                                commodity.premium,
                                3.674,
                                commodity.purity,
                                commodity.unit,
                                commodity.unitLabel === "TTB"
                                  ? 116.64
                                  : commodity.unitLabel === "KG"
                                  ? 1000
                                  : commodity.unitLabel === "OZ"
                                  ? 31.1
                                  : commodity.unitLabel === "TOLA"
                                  ? 11.7
                                  : 1,
                                commodity.charges
                              )}
                        </Typography>
                      </Box>
                    </Box>
                  );
                })}
              </Box>
            </Box>
          </Box>
          <Box className={classes.updates}>
            <Box className={classes.updatesContent}>
              <marquee
                style={{
                  height: "60%",
                  display: "flex",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <Box sx={{ display: "flex" }}>
                  {!adminData?.isNewsEnable &&
                  Object.keys(goldNews).length > 0 ? (
                    <Box
                      style={{
                        fontSize: "1.8vw",
                        fontFamily: "poppins",
                        alignItems: "center",
                        display: "flex",
                      }}
                    >
                      <span style={{ fontWeight: "bold" }}>
                        Gold Price News
                      </span>
                      &nbsp;&nbsp;:&nbsp;&nbsp;<span>{goldNews?.title}</span>
                    </Box>
                  ) : (
                    news?.map((item, index) => {
                      return (
                        <Box
                          key={item._id}
                          style={{
                            fontSize: "1.5vw",
                            fontFamily: "poppins",
                            alignItems: "center",
                            display: "flex",
                          }}
                        >
                          <span style={{ fontWeight: "bold" }}>
                            {item.newsTitle}
                          </span>
                          &nbsp;&nbsp;:&nbsp;&nbsp;
                          <span>{item.newsDetails}</span>
                          <span
                            style={{
                              paddingRight: "50px",
                              paddingLeft: "50px",
                            }}
                          >
                            {index === news.length - 1 ? "" : "|"}
                          </span>
                        </Box>
                      );
                    })
                  )}
                </Box>
              </marquee>
            </Box>
          </Box>
        </Box>
      </Box>
      <Modal open={openBlocked} closeAfterTransition>
        <Blocked handleClose={handleCloseBlocked} />
      </Modal>
      <Modal open={openSubscriptionExpierd} closeAfterTransition>
        <SubscriptionExpired handleClose={handleCloseSubscriptionExpierd} />
      </Modal>
      <Modal
        open={openSubscriptionExpireSoon}
        onClose={handleOpenExpireSoonClose}
        closeAfterTransition
        BackdropProps={{
          style: {
            backgroundColor: "rgba(0, 0, 0, 0.1)", // Adjust the color and opacity as needed
            backdropFilter: "none", // This disables the blur effect
          },
        }}
      >
        <SubscriptionExpiringSoon
          date={adminData?.package.expire_date}
          handleClose={handleOpenExpireSoonClose}
        />
      </Modal>
      <Modal
        open={openNews}
        onClose={handleCloseopenNews}
        closeAfterTransition
        hideBackdrop
      >
        <EconomicNewsModal
          handleClose={handleCloseopenNews}
          curEconomicNews={curEconomicNews}
        />
      </Modal>
    </div>
  );
};

export default Home;
